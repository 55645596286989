import React, { useState, useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { observer } from "mobx-react";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTicketsStore } from "../../hooks/useTicketsStore";
import { makeValidationSchema } from "./makeValidationSchema";
import { searchDeliveryRegion } from "../../utils/data";
import { Box, Layer, RadioButton } from "grommet";
import { getLSValue } from "../../utils/storage";
import { times } from "../../utils/date";
import {
  StyledA,
  StyledButton,
  StyledButtonCancelReg,
  StyledErrorMessage,
  StyledFooter,
  StyledForm,
  StyledFormWrap,
  StyledParagraph,
  StyledPopupButton,
  StyledPopupButtons,
  StyledPopupContent,
  StyledRadioButtons,
  StyledSelect,
  StyledText,
  StyledTitle,
  StyledTransferA,
  StyledDiv,
  StyledBox,
  StyledIconSvg,
  StyledErrorRegMessage,
  StyledCancelMessage,
} from "./styles";
import { useDataStore } from "../../hooks/useDataStore";
import { setCountOptions, setTeamsOptions } from "../../mocks/calendarOptions";
import { routes } from "../../const/routes";
import { TransferTicketForm } from "../../components/TransferTicketForm";
import { useSubmit } from "./hooks/useSubmit";
import { searchSize, searchTeam } from "../../utils/utils";
import { TextInput } from "../../components/Inputs/TextInput";
import { DatePicker } from "../../components/Inputs/DatePicker";
import { InputPhone } from "../../components/Inputs/PhoneInput";
import { Container } from "../../components/Container";
import { TicketCard } from "../../components/TicketCard";
import { CustomSelect } from "../../components/CustomSelect";
import styles from "../../components/Popups/LicensePopup/License.module.scss";
import { toJS } from "mobx";

export const Ticket = observer(() => {
  const params = useParams();
  const navigate = useNavigate();

  const {
    getTicketById,
    registrationTicket,
    cancelRegPopup,
    isCancelRegPopup,
    cancelRegMessage,
    cancelRegistrationTicket,
    isCancelRegMessage,
    transferTicketMessage,
    registrationMessage,
    sendTicket,
    setTransferTicketMessage,
    returnTransferTicketMessage,
    setReturnTransferMessage,
    returnTransferTicket,
    isCancelBuyPopup,
    isCancelBuyMessage,
    cancelBuyPopup,
    cancelBuyTicketMessage,
    cancelBuyTicket,
    isTicketCanceled,
    refundSum,
    regMessage,
    tickets,
    loadTickets,
  } = useTicketsStore();

  const {
    sizes,
    loadSize,
    regions,
    loadDeliveryRegions,
    loadFormatsByFormatId,
    formats,
    teams,
    loadTeams,
    clearSize,
    cleanFormats,
  } = useDataStore();

  const ticket = getTicketById(params.id);
  const format =
    formats?.length &&
    formats?.find((el) => el.public_id === ticket?.event_format?.public_id);
  const formatClusters = format?.clusters ?? [];
  const userObj = JSON.parse(localStorage.getItem("user"));

  const isTicketRegistered = ticket && ticket.info.registered;
  const isCluster = formatClusters.length;
  const isTransferTicket =
    ticket && ticket.original_user_public_id !== userObj?.public_id;
  const isSize = sizes.length > 0;
  const isRegion = ticket && ticket.event_format.regions;
  const isTeams = ticket && ticket.info.type_team === "place";
  const teamTitle =
    isTicketRegistered &&
    searchTeam(ticket.info?.team?.public_id, teams).value === null
      ? "Пройдите регистрацию повторно и выберите команду"
      : "Ваша команда";

  const validateForm = makeValidationSchema(
    isSize,
    isRegion,
    isTeams,
    isCluster
  );
  const isVipUser = getLSValue("userObj", "role") === "vip";
  const isDisabled =
    isTicketRegistered ||
    (isVipUser && ticket.info.discounted) ||
    !isTransferTicket;
  const defaultFirstName = isTicketRegistered
    ? ticket.info.first_name
    : userObj?.name || "";
  const defaultCluster = isTicketRegistered ? ticket.info.cluster?.name : null;

  const defaultSecondName = isTicketRegistered
    ? ticket.info.second_name
    : userObj?.second_name || "";
  const defaultLastName = isTicketRegistered
    ? ticket.info.last_name
    : userObj?.last_name || "";
  const defaultBirthDate = isTicketRegistered
    ? times.getNumberFullDate(ticket.info.birth_date)
    : new Date(userObj?.birth_date * 1000)
        .toLocaleDateString("en-GB")
        .replaceAll("/", ".") || "";
  const defaultPhone = isTicketRegistered
    ? `+${ticket.info.phone}`
    : `+${userObj?.phone}` || "";
  const userEmail = userObj?.email || "";
  const defaultAdditionalFields =
    ticket && ticket.info.additional_fields && ticket.info.additional_fields;
  const defaultSize =
    isTicketRegistered && ticket.info.item_size
      ? ticket.info.item_size
      : searchSize("Футболка не выбрана", sizes)?.label;
  const defaultRegion =
    isTicketRegistered && ticket.info.region
      ? searchDeliveryRegion(regions, ticket.info.region, "region")?.value
      : "";
  const defaultTeam =
    isTicketRegistered && ticket.info?.team?.public_id
      ? searchTeam(ticket.info?.team?.public_id, teams)?.value
      : "";
  const fields = (ticket && ticket.event_format.additional_fields) || [];
  const isSecondName =
    ticket?.info.insurance || ticket?.event_format.second_name;

  const {
    handleSubmit,
    formState: { errors },
    clearErrors,
    setError,
    control,
    setValue,
  } = useForm({
    resolver: yupResolver(validateForm),
    shouldFocusError: false,
    defaultValues: {
      firstName: defaultFirstName,
      lastName: defaultLastName,
      phone: defaultPhone,
      birth: defaultBirthDate,
      size:
        isTicketRegistered && ticket.info.item_size
          ? ticket.info.item_size
          : searchSize("Футболка не выбрана", sizes)?.label,
    },
  });

  const [genderError, setGenderError] = useState("");
  const [gender, setGender] = useState(userObj?.gender || "");
  const [filteredRegions, setFilteredRegions] = useState([]);
  const [isOpenTransferPopup, setIsOpenTransferPopup] = useState(false);
  const [isOpenReturnTicketPopup, setIsReturnTicketPopup] = useState(false);
  const [cluster, setCluster] = useState(null);
  const [isLoadFile, setIsLoadFile] = useState(false);
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState(null);

  const { onSubmit } = useSubmit({
    gender,
    setGenderError,
    ticket,
    registrationTicket,
    fields,
    setError,
    format,
    isLoadFile,
    file,
    setFileError,
  });

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setFileError("");
  };

  const handleGenderSelect = (value) => {
    setGender(value);
    setGenderError("");
  };

  const handleReturnTicket = () => {
    if (!isTransferTicket) {
      returnTransferTicket({ public_id: ticket.public_id });
    }
  };
  const handleCloseCancelBuyPopup = () => {
    cancelBuyPopup(false);
    cancelBuyTicketMessage("");
  };

  const handleCloseCancelRegPopup = () => {
    cancelRegPopup(false);
    cancelRegMessage("");
  };
  const handleCancelBuyTicket = () => {
    cancelBuyTicket({ ticket: { public_id: ticket.public_id } });
  };
  const handleBackToEvents = () => {
    cancelBuyTicket(false);
    navigate(`${routes.me.path}/events`, { replace: true });
  };
  const handleCancelRegTicket = () => {
    const canceledInfo = {
      format_public_id: ticket.event_format.public_id,
      public_id: ticket.public_id,
    };
    cancelRegistrationTicket(canceledInfo);
  };

  useEffect(() => {
    if (formatClusters?.length > 0 && cluster) {
      const currentCluster = formatClusters.find((el) => el.value === cluster);

      if (currentCluster.file) {
        setIsLoadFile(true);
      } else {
        setIsLoadFile(false);
      }
    }
  }, [cluster]);

  useEffect(() => {
    if (ticket && !formats.length) {
      loadFormatsByFormatId(ticket?.event_format.public_id);
    }
    return () => cleanFormats([]);
  }, [ticket]);

  useEffect(() => {
    if (sizes.length > 0) {
      setValue("size", searchSize("Футболка не выбрана", sizes)?.label);
    }
  }, [sizes]);

  useEffect(() => {
    if (!tickets.length) loadTickets();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (ticket) {
      loadSize({
        event_city_public_id: ticket.event_format.event_city.public_id,
      });
      loadDeliveryRegions();
    }
    if (ticket && ticket.info.type_team === "place") {
      loadTeams(ticket.event_format.public_id);
    }
    return () => clearSize();
    //eslint-disable-next-line
  }, [ticket]);

  useEffect(() => {
    let filteredRegions = [];
    if (
      ticket &&
      ticket.event_format.regions &&
      ticket.event_format.regions.length > 0
    ) {
      ticket.event_format.regions.forEach((el) => {
        filteredRegions.push(searchDeliveryRegion(regions, el, "region"));
      });
    } else if (ticket && ticket.event_format.regions) {
      filteredRegions = regions.map((el) => {
        return { id: el.id, label: el.name_ru, value: el.id };
      });
    }
    setFilteredRegions(filteredRegions);
    //eslint-disable-next-line
  }, [ticket, regions]);

  useEffect(() => {
    if (returnTransferTicketMessage) {
      setIsReturnTicketPopup(true);
      setReturnTransferMessage("");
    }
    if (transferTicketMessage) {
      setIsOpenTransferPopup(true);
      setTransferTicketMessage("");
    }
    //eslint-disable-next-line
  }, [transferTicketMessage, returnTransferTicketMessage]);

  useEffect(() => {
    if (regMessage === true || isCancelRegMessage === true) {
      navigate(`${routes.me.path}/events`, { replace: true });
    }
    return () => {
      registrationMessage("");
      cancelRegMessage("");
    };
    //eslint-disable-next-line
  }, [regMessage, isCancelRegMessage]);

  return (
    <Box style={{ marginTop: "40px", marginBottom: "40px" }}>
      <Container>
        <TicketCard ticket={ticket} />
        {ticket && (
          <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <StyledTitle>Данные участника</StyledTitle>
            {!ticket.info.registered && (
              <StyledParagraph>
                Необходимо заполнить обязательные данные участника
              </StyledParagraph>
            )}
            <StyledErrorRegMessage>
              {regMessage && regMessage !== true && regMessage}
            </StyledErrorRegMessage>
            <StyledFormWrap>
              <Controller
                control={control}
                name="firstName"
                render={({ field: { onChange } }) => (
                  <TextInput
                    error={errors.firstName?.message}
                    handleFocus={() => clearErrors("firstName")}
                    label="Ваше имя"
                    iconSrc="user"
                    disabled={isDisabled}
                    handleChange={onChange}
                    placeholder="Ваше имя"
                    defaultValue={defaultFirstName}
                  />
                )}
              />
              <Controller
                control={control}
                name="lastName"
                render={({ field: { onChange } }) => (
                  <TextInput
                    disabled={isDisabled}
                    error={errors.lastName?.message}
                    handleFocus={() => clearErrors("lastName")}
                    label="Ваша Фамилия"
                    handleChange={onChange}
                    iconSrc="user"
                    placeholder="Ваша Фамилия"
                    defaultValue={defaultLastName}
                  />
                )}
              />
              {isSecondName && (
                <Controller
                  control={control}
                  name="secondName"
                  render={({ field: { onChange } }) => (
                    <TextInput
                      disabled={isDisabled}
                      error={errors.secondName?.message}
                      handleFocus={() => clearErrors("secondName")}
                      label="Ваше Отчество"
                      handleChange={onChange}
                      iconSrc="user"
                      placeholder="Если есть"
                      defaultValue={defaultSecondName}
                    />
                  )}
                />
              )}
              <Controller
                control={control}
                name="birth"
                render={({ field: { onChange, value } }) => (
                  <DatePicker
                    disabled={isDisabled}
                    error={errors.birth?.message}
                    value={value}
                    onFocus={() => clearErrors("birth")}
                    label="День рождения "
                    id="birthDate"
                    onChange={onChange}
                    placeholder="30.12.2000"
                    defaultValue={defaultBirthDate}
                  />
                )}
              />
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <InputPhone
                    disabled={isDisabled}
                    country="RU"
                    international={true}
                    withCountryCallingCode={true}
                    error={errors.phone?.message}
                    name="phone"
                    onFocus={() => clearErrors("phone")}
                    defaultValue={`+${defaultPhone}`}
                    {...field}
                  />
                )}
              />

              {sizes.length > 0 && (
                <StyledDiv>
                  <StyledParagraph
                    disabled={isDisabled}
                    error={errors.size?.message}
                  >
                    {errors.size?.message
                      ? errors.size.message
                      : "Размер футболки"}
                  </StyledParagraph>
                  <StyledBox error={errors.size?.message}>
                    <StyledIconSvg
                      disabled={isDisabled}
                      error={errors.size?.message}
                      src="tShirt"
                    />
                    <Controller
                      control={control}
                      name="size"
                      render={({ field: { onChange } }) => (
                        <CustomSelect
                          options={setCountOptions(sizes, "size")}
                          placeholder="Выберите ваш размер"
                          onChange={(event) => {
                            onChange(event.target.value);
                          }}
                          defaultValue={defaultSize}
                          onFocus={() => clearErrors("size")}
                          disabled={isDisabled}
                        />
                      )}
                    />
                  </StyledBox>
                </StyledDiv>
              )}

              {ticket.event_format.regions ? (
                <StyledDiv error={errors.region?.message}>
                  <StyledParagraph error={errors.region?.message}>
                    {errors.region?.message ? errors.region.message : "Регион"}
                  </StyledParagraph>
                  <Controller
                    control={control}
                    name="region"
                    render={({ field: { onChange } }) => (
                      <StyledSelect
                        disabled={isDisabled}
                        onFocus={() => clearErrors("region")}
                        onChange={({ value }) => onChange(value)}
                        valueKey={{ key: "value", reduce: true }}
                        labelKey="label"
                        options={filteredRegions}
                        placeholder="Выберите ваш регион"
                        defaultValue={defaultRegion}
                      />
                    )}
                  />
                </StyledDiv>
              ) : null}
              {ticket.info.type_team === "place" && teams?.length > 0 ? (
                <StyledDiv error={errors.team?.message}>
                  <StyledParagraph error={errors.team?.message}>
                    {errors.team?.message ? errors.team.message : teamTitle}
                  </StyledParagraph>
                  <Controller
                    control={control}
                    name="team"
                    render={({ field: { onChange } }) => (
                      <StyledSelect
                        disabled={isDisabled}
                        onFocus={() => clearErrors("team")}
                        onChange={({ value }) => onChange(value)}
                        valueKey={{ key: "value", reduce: true }}
                        labelKey="label"
                        options={setTeamsOptions(
                          teams,
                          "public_id",
                          "number",
                          ticket
                        )}
                        placeholder="Выберите команду"
                        defaultValue={defaultTeam}
                      />
                    )}
                  />
                </StyledDiv>
              ) : null}

              {fields &&
                !ticket.info.registered &&
                fields.map((el) => (
                  <Controller
                    key={el.name}
                    control={control}
                    name={el.name}
                    render={({ field: { onChange } }) => (
                      <TextInput
                        iconSrc="default"
                        disabled={isDisabled}
                        error={errors[`${el.name}`]?.message}
                        handleFocus={() => clearErrors(el.name)}
                        label={el.label}
                        handleChange={onChange}
                      />
                    )}
                  />
                ))}

              {defaultAdditionalFields &&
                ticket.info.registered &&
                defaultAdditionalFields.map((field) => (
                  <TextInput
                    iconSrc="default"
                    disabled={isDisabled}
                    key={field.name}
                    label={field.label}
                    name={field.name}
                    defaultValue={field.value}
                  />
                ))}

              <div>
                <StyledParagraph disabled={isDisabled}>
                  Ваш пол:
                </StyledParagraph>
                {genderError.length ? (
                  <StyledErrorMessage>{genderError}</StyledErrorMessage>
                ) : null}
                <StyledRadioButtons disabled={isDisabled}>
                  <RadioButton
                    name="gender"
                    checked={gender === "male"}
                    label="Мужской"
                    value="male"
                    onChange={(event) => handleGenderSelect(event.target.value)}
                  />
                  <RadioButton
                    name="gender"
                    value="female"
                    checked={gender === "female"}
                    label="Женский"
                    onChange={(event) => handleGenderSelect(event.target.value)}
                  />
                </StyledRadioButtons>
              </div>
              {formatClusters.length > 0 && (
                <StyledDiv error={errors.cluster?.message}>
                  <StyledParagraph error={errors.cluster?.message}>
                    {errors.cluster?.message
                      ? errors.cluster.message
                      : "Кластер"}
                  </StyledParagraph>
                  <Controller
                    control={control}
                    name="cluster"
                    render={({ field: { onChange, value } }) => (
                      <StyledSelect
                        disabled={isDisabled}
                        onFocus={() => clearErrors("cluster")}
                        onChange={({ value }) => {
                          onChange(value);
                          setCluster(value);
                        }}
                        valueKey={{ key: "value", reduce: true }}
                        labelKey="label"
                        options={formatClusters}
                        placeholder="Выберите ваш кластер"
                        value={value}
                        defaultValue={defaultCluster}
                      />
                    )}
                  />
                </StyledDiv>
              )}

              {isLoadFile && (
                <label
                  className={`${styles.labelFileBasket} ${
                    isDisabled && styles.disabled
                  }`}
                >
                  Подтверждение***
                  <span
                    className={`${styles.textFile} ${
                      fileError && styles.labelFileError
                    } ${isDisabled && styles.registered}`}
                  >
                    {isDisabled ? "Документ загружен" : "Загрузить документ"}
                    <input
                      className={styles.inputFile}
                      disabled={isDisabled}
                      accept="image/pdf, image/jpeg, image/png , image/jpg"
                      type="file"
                      onChange={handleFileChange}
                      name="file"
                      handleFocus={() => setFileError("")}
                    />
                  </span>
                  {fileError?.length ? <span>{fileError}</span> : null}
                  {file && file.name ? <span>{file.name}</span> : null}
                </label>
              )}
            </StyledFormWrap>

            {format && format.notes && formatClusters.length > 0 ? (
              <ul
                style={{ marginBottom: "2rem" }}
                className={styles.notesWrapper}
              >
                {format.notes.map((el, i) => (
                  <li key={i} className={styles.notes}>
                    {el}
                  </li>
                ))}
              </ul>
            ) : null}
            <StyledFooter>
              {ticket.info.registered && isTransferTicket ? (
                <StyledButton as={Link} to={`${routes.me.path}/events`}>
                  Назад
                </StyledButton>
              ) : (
                <>
                  {!isTransferTicket ? (
                    <StyledButton type="button" onClick={handleReturnTicket}>
                      Вернуть
                    </StyledButton>
                  ) : (
                    <StyledButton type="submit">
                      Зарегистрироваться
                    </StyledButton>
                  )}
                </>
              )}
              {ticket?.info.registered &&
                !ticket.info.offline_registered &&
                isTransferTicket && (
                  <StyledButtonCancelReg
                    type="button"
                    onClick={() => cancelRegPopup(true)}
                  >
                    Отменить регистрацию
                  </StyledButtonCancelReg>
                )}

              {ticket?.event.cancel &&
                !ticket.info.promocode &&
                !ticket.info.offline_registered &&
                !ticket.info.registered > 0 && (
                  <StyledButtonCancelReg
                    type="button"
                    title={
                      ticket.info.registered
                        ? `Чтобы оформить возврат - отмените регистрацию`
                        : ``
                    }
                    onClick={() => cancelBuyPopup(true)}
                    disabled={
                      ticket.info.registered || ticket.info.offline_registered
                    }
                  >
                    {ticket.info.cost > 0
                      ? "Оформить возврат"
                      : "Отказаться от участия"}
                  </StyledButtonCancelReg>
                )}
              {isTransferTicket && (
                <StyledText>
                  При нажатии кнопки зарегистрироваться на сайте <br />
                  Вы даете свое согласие на обработку Ваших{" "}
                  <StyledA href="/files/soglasie.pdf">
                    персональных данных
                  </StyledA>
                </StyledText>
              )}
            </StyledFooter>
          </StyledForm>
        )}

        {ticket &&
          !ticket.info.registered &&
          !ticket.original_user_public_id && (
            <TransferTicketForm
              ticket={ticket}
              email={userEmail}
              transferTicketMessage={transferTicketMessage}
              sendTicket={sendTicket}
            />
          )}
        {isOpenTransferPopup && (
          <Layer>
            <StyledPopupContent>
              <h2>Билет отправлен</h2>
              <StyledTransferA as={Link} to={`${routes.me.path}/events`}>
                Понятно
              </StyledTransferA>
            </StyledPopupContent>
          </Layer>
        )}
        {isOpenReturnTicketPopup && (
          <Layer>
            <StyledPopupContent>
              <h2>Билет снова ваш</h2>
              <StyledTransferA as={Link} to={`${routes.me.path}/events`}>
                Понятно
              </StyledTransferA>
            </StyledPopupContent>
          </Layer>
        )}
        {isCancelRegPopup && (
          <Layer>
            <StyledPopupContent>
              {isCancelRegMessage ? (
                <StyledCancelMessage>{isCancelRegMessage}</StyledCancelMessage>
              ) : (
                <h2>Вы действительно хотите отменить регистрацию?</h2>
              )}
              <div>
                {isCancelRegMessage ? (
                  <StyledPopupButton onClick={handleCloseCancelRegPopup}>
                    Вернуться к билету
                  </StyledPopupButton>
                ) : (
                  <StyledPopupButtons>
                    <StyledPopupButton onClick={handleCancelRegTicket}>
                      Да
                    </StyledPopupButton>
                    <StyledPopupButton onClick={() => cancelRegPopup(false)}>
                      Нет
                    </StyledPopupButton>
                  </StyledPopupButtons>
                )}
              </div>
            </StyledPopupContent>
          </Layer>
        )}
        {isCancelBuyPopup && (
          <Layer>
            <StyledPopupContent>
              {isCancelBuyMessage ? (
                <StyledCancelMessage>{isCancelBuyMessage}</StyledCancelMessage>
              ) : (
                <h2>Вы действительно хотите оформить возврат?</h2>
              )}
              {isCancelBuyMessage ? (
                <StyledPopupButton onClick={handleCloseCancelBuyPopup}>
                  Вернуться к билету
                </StyledPopupButton>
              ) : (
                <StyledPopupButtons>
                  <StyledPopupButton onClick={handleCancelBuyTicket}>
                    Да
                  </StyledPopupButton>
                  <StyledPopupButton onClick={() => cancelBuyPopup(false)}>
                    Нет
                  </StyledPopupButton>
                </StyledPopupButtons>
              )}
            </StyledPopupContent>
          </Layer>
        )}

        {isTicketCanceled && refundSum && (
          <Layer>
            <StyledPopupContent>
              <StyledCancelMessage>
                Возврат на сумму {refundSum} рублей был успешно выполнен, детали
                отправлены на {userEmail}
              </StyledCancelMessage>
              <StyledPopupButtons>
                <StyledPopupButton onClick={handleBackToEvents}>
                  Вернуться к моим мероприятиям
                </StyledPopupButton>
              </StyledPopupButtons>
            </StyledPopupContent>
          </Layer>
        )}
      </Container>
    </Box>
  );
});

import React, { useContext, useEffect } from "react";
import { Box, Heading, ResponsiveContext, Select, Text } from "grommet";
import { isMobileTabletResponsive } from "../../../utils/utils";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { makeValidationSchema } from "./makeValidationSchema";
import styled from "styled-components";
import { IconSvg } from "../../IconSvg";
import { ErrorInfo } from "../../ErrorInfo";
import { optionsRank } from "../../../mocks/calendar";
import { useState } from "react";
import { convertBase64 } from "../../../utils/convertBase64";
import { DefaultButton } from "../../DefaultButton";

export const StyledSelect = styled(Select)`
  padding: 21px 50px;
  color: black;
`;
export const StyledFileInput = styled.input`
  display: none;
`;
export const StyledDocumentFormat = styled.div`
  font-family: "Gotham Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  color: #000000;
`;

export const StyledDocumentLabel = styled.label`
  box-sizing: border-box;
  cursor: pointer;
  padding: 16px 32px;

  border: 1px solid #000000;
  border-radius: 8px;

  white-space: nowrap;

  width: 219px;
  height: 56px;

  font-family: "Gotham Pro";

  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  color: #000000;
`;

export const StyledFileFormat = styled.div`
  font-family: "Gotham Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  color: rgba(0, 0, 0, 0.5);

  opacity: 0.8;
`;
export const StyledFileLabel = styled.label`
  box-sizing: border-box;
  padding: 12px 32px;
  cursor: pointer;
  width: 178px;
  height: 43px;

  border: 1.2px solid #000000;
  border-radius: 4px;

  white-space: nowrap;
`;

export const AdditionalForm = ({
  loadDeliveryRegions,
  regions,
  setAdditionalNewData,
  setMessage,
  getDataMessage,
  getUserInfo,
}) => {
  const validateForm = makeValidationSchema();
  const user = localStorage.user && JSON.parse(localStorage.user);
  const [file, setFile] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [document, setDocument] = useState(null);
  const [filteredRegions, setFilteredRegions] = useState([]);
  const [searchRegions, setSearchRegions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (user?.photo) {
      setPhoto(user.photo);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    loadDeliveryRegions();
    getUserInfo();
    //eslint-disable-next-line
  }, []);

  const onSearch = (str) => {
    const result = filteredRegions.filter((item) => {
      const itemName = JSON.stringify(item).toLowerCase();
      return itemName.includes(str.toLowerCase());
    });
    setSearchRegions(result);
  };

  useEffect(() => {
    let filteredRegions = [];

    filteredRegions = regions.map((el) => {
      return { id: el.id, label: el.name_ru, value: el.id };
    });

    setFilteredRegions(filteredRegions);
    setSearchRegions(filteredRegions);
  }, [regions]);

  const {
    handleSubmit,
    control,
    register,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    resolver: yupResolver(validateForm),
    shouldFocusError: false,
    defaultValues: {
      region: user?.region?.id,
      rank: user?.sport_rank,
      sport_rank_document: user?.sport_rank_document,
      photo: user?.photo,
    },
  });

  const size = useContext(ResponsiveContext);
  const isMobileWidth = isMobileTabletResponsive(size);
  const handleChange = async (e) => {
    const photo = e.target.files[0] && (await convertBase64(e.target.files[0]));
    setPhoto(photo);
    setFile(e.target.files[0]);
    setValue("file", e.target.files[0]);
  };

  const onSubmit = async ({ region, rank, file, document }) => {
    const newFile = file.length === 0 ? null : file;
    const newDocument = document.length === 0 ? null : document;
    const photo = newFile && (await convertBase64(newFile));
    if (file.length !== 0) setPhoto(photo);
    const sport_rank_document =
      newDocument && (await convertBase64(newDocument));

    let newData = {
      sport_rank: rank,
      region_id: Number(region),
    };

    if (photo) {
      newData = {
        ...newData,
        photo,
      };
    }
    if (sport_rank_document) {
      newData = {
        ...newData,
        sport_rank_document,
      };
    }

    if (
      user.sport_rank !== rank &&
      user?.region?.id !== Number(region) &&
      sport_rank_document &&
      photo
    ) {
      setAdditionalNewData(newData, setIsLoading);
      setFile(null);
      setDocument(null);
    } else {
      setMessage("");
      if (user.photo !== photo && photo) {
        setAdditionalNewData({ photo }, setIsLoading);
        setFile(null);
        setIsLoading(true);
      }
      if (user.sport_rank !== rank && rank) {
        setAdditionalNewData({ sport_rank: rank }, setIsLoading);
      }
      if (region && user?.region?.id !== Number(region)) {
        region &&
          setAdditionalNewData({ region_id: Number(region) }, setIsLoading);
      }
      if (
        user.sport_rank_document !== sport_rank_document &&
        sport_rank_document
      ) {
        setAdditionalNewData({ sport_rank_document }, setIsLoading);
        setDocument(null);
        setIsLoading(true);
      }
      if (user.sport_rank === rank && user?.region?.id === Number(region)) {
        setMessage("Данные не изменились!");
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Heading
          level={3}
          margin={{ top: "24px", bottom: "40px" }}
          style={{ color: "black" }}
        >
          Дополнительная информация
        </Heading>
        <Box
          margin={{ bottom: "40px" }}
          flex
          direction="row"
          align="center"
          gap="25px"
        >
          {photo ? (
            <img
              width="150px"
              height="150px"
              style={{ marginBottom: "20px", borderRadius: "100px" }}
              src={photo}
              alt="аватар пользователя"
            />
          ) : (
            <IconSvg src="userAvaLoad" />
          )}
          <Box flex>
            <StyledFileLabel>
              Загрузить фото
              <StyledFileInput
                {...register("file")}
                type="file"
                onFocus={() => clearErrors("file")}
                onChange={handleChange}
                name="file"
              />
            </StyledFileLabel>
            {errors.file?.message && (
              <span style={{ marginTop: "10px", position: "relative" }}>
                <ErrorInfo error={errors.file?.message} />
              </span>
            )}
            <Box margin={{ top: "8px" }}>
              <StyledFileFormat>Форматы - JPEG, JPG, PNG</StyledFileFormat>
              <StyledFileFormat>
                {file ? file.name : "Файл не выбран"}
              </StyledFileFormat>
            </Box>
          </Box>
        </Box>
        <Box flex gap={isMobileWidth ? "1rem" : "2rem"}>
          <Box flex direction={isMobileWidth ? "column" : "row"} gap="50px">
            <Box style={{ width: "410px", minWidth: "300px" }}>
              <label
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  position: "relative",
                }}
              >
                <span
                  style={{ position: "absolute", top: "40px", left: "21px" }}
                >
                  <IconSvg
                    color={errors.rank?.message ? "#CA3C3C" : "black"}
                    src="rect"
                    height="40px"
                    width={false}
                  />
                </span>
                <span
                  style={{ position: "absolute", top: "33.5px", right: "45px" }}
                >
                  <IconSvg
                    color={errors.rank?.message ? "#CA3C3C" : "black"}
                    src="rect"
                    height="55.5px"
                    width={false}
                  />
                </span>
                <span
                  style={{
                    fontWeight: "700",
                    fontSize: "14px",
                    color: `${errors.rank?.message ? "#CA3C3C" : "black"}`,
                  }}
                >
                  Ваш разряд
                </span>
                <Controller
                  control={control}
                  name="rank"
                  render={({ field: { onChange } }) => (
                    <StyledSelect
                      multiple={false}
                      icon={
                        <span style={{ marginRight: "10px" }}>
                          <IconSvg src="dropButton" />
                        </span>
                      }
                      onFocus={() => clearErrors("rank")}
                      onChange={({ value }) => onChange(value)}
                      valueKey={{ key: "value", reduce: true }}
                      labelKey="label"
                      options={optionsRank}
                      defaultValue={user?.sport_rank}
                      placeholder="Ваш разряд"
                    />
                  )}
                />
              </label>
              <span style={{ marginTop: "10px", position: "relative" }}>
                <ErrorInfo error={errors.rank?.message} />
              </span>
            </Box>
            <Box style={{ width: "410px", minWidth: "300px" }}>
              <label
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                  position: "relative",
                }}
              >
                <span
                  style={{ position: "absolute", top: "40px", left: "21px" }}
                >
                  <IconSvg
                    color={errors.region?.message ? "#CA3C3C" : "black"}
                    src="rect"
                    height="40px"
                    width={false}
                  />
                </span>
                <span
                  style={{ position: "absolute", top: "33.5px", right: "45px" }}
                >
                  <IconSvg
                    color={errors.region?.message ? "#CA3C3C" : "black"}
                    src="rect"
                    height="55.5px"
                    width={false}
                  />
                </span>
                <span
                  style={{
                    fontWeight: "700",
                    fontSize: "14px",
                    color: `${errors.region?.message ? "#CA3C3C" : "black"}`,
                  }}
                >
                  Ваш регион
                </span>
                <Controller
                  control={control}
                  name="region"
                  render={({ field: { onChange } }) => (
                    <StyledSelect
                      defaultValue={user?.region?.id}
                      multiple={false}
                      onSearch={onSearch}
                      icon={
                        <span style={{ marginRight: "10px" }}>
                          <IconSvg src="dropButton" />
                        </span>
                      }
                      onFocus={() => clearErrors("region")}
                      onClose={() => setSearchRegions(filteredRegions)}
                      focusIndicator={false}
                      plain={false}
                      replace={false}
                      onChange={({ value }) => onChange(value)}
                      valueKey={{ key: "value", reduce: true }}
                      labelKey="label"
                      options={searchRegions}
                      placeholder="Ваш регион"
                    />
                  )}
                />
              </label>
              <span style={{ marginTop: "10px", position: "relative" }}>
                <ErrorInfo error={errors.region?.message} />
              </span>
            </Box>
          </Box>
          <Box flex>
            <StyledDocumentLabel>
              Загрузить документ
              <StyledFileInput
                {...register("document")}
                type="file"
                onFocus={() => clearErrors("document")}
                onChange={(e) => {
                  setDocument(e.target.files[0]);
                  setValue("document", e.target.files[0]);
                }}
                name="document"
              />
            </StyledDocumentLabel>
            {errors.document?.message && (
              <span style={{ marginTop: "10px", position: "relative" }}>
                <ErrorInfo error={errors.document?.message} />
              </span>
            )}
            <Box margin={{ top: "8px" }}>
              <StyledDocumentFormat>
                Форматы - JPEG, JPG, PNG, PDF
              </StyledDocumentFormat>
              <StyledDocumentFormat>
                {document ? document.name : "Файл не выбран"}
              </StyledDocumentFormat>
            </Box>
          </Box>
          <Box
            width={{ max: "406px" }}
            margin={{ top: isMobileWidth && "20px" }}
          >
            <DefaultButton
              type="submit"
              style={{
                padding: "10px",
                width: "100%",
                textAlign: "center",
                borderRadius: "8px",
                height: "56px",
              }}
            >
              Сохранить
            </DefaultButton>
            <Box pad={{ top: "1rem", bottom: "1rem" }}>
              <Text size="small">{isLoading ? "" : getDataMessage}</Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </form>
  );
};

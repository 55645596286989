import * as yup from "yup";
import { REGEX_MAIL } from "../../../const/regex";

const user = localStorage.user && JSON.parse(localStorage.user);
const userEmail = user?.email;

export function makeValidationSchema() {
  return yup.object().shape({
    email: yup
      .string()
      .required()
      .test("emailIslValid", "Введите валидный email", (value) => {
        const testValue = REGEX_MAIL.test(value);

        if (!testValue) return false;
        if (testValue) return true;
      })
      .test(
        "emailIsNew",
        "Чтобы изменить почту введите новое значение",
        (value) => {
          if (userEmail === value) return false;
          if (userEmail !== value) return true;
        }
      ),
  });
}

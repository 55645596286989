import React, { useState } from "react";
import { Box, Heading } from "grommet";
import { RoundCheckBox } from "../../Checkboxes/RoundCheckBox";

export const Gender = ({ setNewData }) => {
  const userObject = localStorage?.user && JSON.parse(localStorage.user);

  const [gender, setGender] = useState(userObject?.gender || "");

  const handleGender = (event) => {
    const { value } = event.target;
    if (value) {
      setGender(value);
      setNewData({ gender: value });
    }
  };

  return (
    <Box>
      <Heading level={6} style={{ color: "black" }}>
        Укажите ваш пол
      </Heading>
      <Box flex direction="row" margin={{ top: "1.5rem" }}>
        <Box flex style={{ maxWidth: "120px" }}>
          <RoundCheckBox
            id="male"
            name="gender"
            value="male"
            label="Мужской"
            checked={gender === "male"}
            onChange={handleGender}
            // disabled={disabled}
          />
        </Box>

        <Box flex style={{ marginLeft: "20px" }}>
          <RoundCheckBox
            id="female"
            name="gender"
            value="female"
            label="Женский"
            checked={gender === "female"}
            onChange={handleGender}
            // disabled={disabled}
          />
        </Box>
      </Box>
    </Box>
  );
};
